import React from 'react';
import { Box, Typography } from '@mui/material';

const HostPill = ({ hostName, size = 'xsmall' }: { hostName: string, size?: 'xsmall' | 'small' | 'medium' }) => (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        backgroundColor: 'rgba(28, 28, 28, 0.8)',
        borderRadius: '100px',
        padding: '4px 8px',
        width: 'fit-content'
    }}>
        <Typography
            component="span"
            variant="body2"
            sx={{
                fontSize: size === 'xsmall' ? '11pt' : size === 'small' ? '12pt' : '14pt'
            }}
        >
            🔥
        </Typography>
        <Typography
            variant="body2"
            color="text.secondary"
            sx={{
                fontSize: size === 'xsmall' ? '11pt' : size === 'small' ? '12pt' : '14pt',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '75vw',
            }}
        >
            Gehost door {hostName}
        </Typography>
    </Box>
);

export default HostPill;
