import { Box, Button, Card, CardActions, CardHeader, IconButton, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

const LOCAL_STORAGE_KEY = 'hasSeenOrganizeEventCard';

const OrganizeEventCard = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const hasSeenCard = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (hasSeenCard === 'true') {
            setIsVisible(false);
        }
    }, []);

    const handleClose = () => {
        localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
        setIsVisible(false);
    };

    const handleInstagramClick = () => {
        window.open('https://www.instagram.com/carmeeterapp/', '_blank');
    };

    const handleEmailClick = () => {
        window.location.href = 'mailto:info@carmeeter.com?subject=Ik wil mijn meet beheren op CarMeeter';
    };

    if (!isVisible) return null;

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                bgcolor: 'background.paper',
                cursor: 'default',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
                WebkitTapHighlightColor: 'transparent',
                position: 'relative',
            }}
        >
            <IconButton
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    zIndex: 1,
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box sx={{ minWidth: '250px', width: '100%', position: 'relative' }}>
                <CardHeader
                    sx={{
                        paddingBottom: 0,
                        paddingTop: 1,
                        paddingRight: 6,
                    }}
                    title={
                        <Typography sx={{ fontWeight: 'bold', fontSize: '14pt' }} component="span">
                            Staat jouw eigen meet al in de lijst?
                        </Typography>
                    }
                    subheader={
                        <Box>
                            <Box display='flex' minHeight='45px'>
                                <Typography variant='body2' color='text.secondary'>
                                    Laat het ons weten via Instagram of e-mail, dan zorgen we ervoor dat jij je eigen meets kan beheren op CarMeeter!
                                </Typography>
                            </Box>
                        </Box>
                    }
                />
                <CardActions disableSpacing sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end', gap: 1 }}>
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<InstagramIcon />}
                        onClick={handleInstagramClick}
                    >
                        Instagram
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<EmailIcon />}
                        onClick={handleEmailClick}
                    >
                        E-mail
                    </Button>
                </CardActions>
            </Box>
        </Card>
    );
};

export default OrganizeEventCard; 