import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useStytch, useStytchUser } from '@stytch/react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../theme';
import ImageCarousel from '../components/ImageCarousel';
import { useUser } from '../hooks/useUser';
import { useSession, ACTIVITY_TIMEOUT } from '../hooks/useSession';

const RegisterHost = () => {
    const { user: stytchUser } = useStytchUser();
    const stytch = useStytch();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [hostName, setHostName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState<'weak' | 'strong' | null>(null);
    const navigate = useNavigate();
    const { user, createUser } = useUser();
    const [existingUser, setExistingUser] = useState(false);

    useEffect(() => {
        const checkUser = async () => {
            if (user) {
                if (user?.role === 'host') {
                    navigate('/');
                    return;
                }
                setExistingUser(true);
            }
        };
        checkUser();
    }, [stytchUser]);

    const checkPasswordStrength = (password: string): 'weak' | 'strong' => {
        return password.length >= 8 ? 'strong' : 'weak';
    };

    const handleAuth = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setPasswordStrength(null);

        try {
            if (!hostName.trim()) {
                setError('Vul een geldige host naam in.');
                setIsLoading(false);
                return;
            }

            if (existingUser && stytchUser) {
                // Update existing user to host
                await createUser(stytchUser.emails[0].email, stytch.session.getTokens()?.session_token || '', {
                    role: 'host',
                    hostTier: 'free',
                    hostName: hostName
                });
                navigate('/');
                return;
            }

            // New user registration
            if (password !== confirmPassword) {
                setError('Wachtwoorden komen niet overeen.');
                setIsLoading(false);
                return;
            }

            const response = await stytch.passwords.create({
                email,
                password,
                session_duration_minutes: ACTIVITY_TIMEOUT,
            });

            if (response.status_code === 200) {
                try {
                    await createUser(email, response.session_token, {
                        role: 'host',
                        hostTier: 'free',
                        hostName: hostName
                    });

                    const baseUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || window.location.origin;
                    const verifyUrl = new URL('/verify-email', baseUrl).toString();

                    await stytch.magicLinks.email.send(
                        email,
                        {
                            signup_magic_link_url: verifyUrl,
                            login_magic_link_url: verifyUrl,
                            signup_expiration_minutes: 60,
                            login_expiration_minutes: 60
                        }
                    );

                    navigate('/verify-email-sent', {
                        state: {
                            email,
                            message: 'E-mail succesvol verzonden. Controleer je inbox.'
                        },
                        replace: true
                    });
                } catch (error) {
                    console.error('Error during host registration:', error);
                    setError('Er ging iets mis bij het aanmaken van je account. Probeer het opnieuw.');
                    throw error;
                }
            }
        } catch (error: any) {
            if (error.error_type === 'user_not_found') {
                setError('Gebruiker niet gevonden.');
            } else if (error.status_code === 401) {
                setError('Ongeldige e-mail/wachtwoord combinatie.');
            } else if (error.error_type === 'invalid_email') {
                setError('Ongeldig e-mailadres.');
            } else if (error.status_code === 400 && error.error_message === 'password appears in a list of breached passwords.') {
                setError('Dit wachtwoord is te vaak gebruikt en mogelijk onveilig. Kies een ander wachtwoord.');
            } else if (error.error_type === 'duplicate_email') {
                setError('Er bestaat al een account met dit e-mailadres.');
            } else {
                setError('Er ging iets mis. Probeer het opnieuw.');
            }
            console.error('Registration error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            position: 'relative',
            overflow: 'hidden',
            paddingTop: '300px',
        }}>
            <Box sx={{
                position: 'absolute',
                top: '25px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
                <ImageCarousel />
            </Box>
            <Box sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                top: '200px',
                '@media (max-width: 768px)': {
                    top: '150px',
                },
                left: 0,
                width: '100%',
                height: 'auto',
                zIndex: 1000,
            }}>
                <Box
                    component="form"
                    onSubmit={handleAuth}
                    sx={{
                        width: '320px',
                        backgroundColor: theme.palette.background.paper,
                        padding: 3,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        {existingUser ? 'Word een Host' : 'Registreer als Host'}
                    </Typography>

                    {!existingUser && (
                        <>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                sx={{ mb: 2 }}
                                required
                                autoComplete="email"
                                inputProps={{
                                    autoCapitalize: 'none',
                                    autoCorrect: 'off'
                                }}
                            />

                            <TextField
                                fullWidth
                                label="Wachtwoord"
                                variant="outlined"
                                type="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setPasswordStrength(checkPasswordStrength(e.target.value));
                                    setError('');
                                }}
                                sx={{ mb: 2 }}
                                required
                            />

                            <TextField
                                fullWidth
                                label="Bevestig wachtwoord"
                                variant="outlined"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                sx={{ mb: 2 }}
                                required
                            />
                        </>
                    )}

                    <TextField
                        fullWidth
                        label="Host naam"
                        variant="outlined"
                        value={hostName}
                        onChange={(e) => setHostName(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                        inputProps={{
                            autoCapitalize: 'none',
                            autoCorrect: 'off',
                            minLength: 3,
                            maxLength: 28,
                        }}
                        helperText="Gebruik 3 tot 28 tekens"
                    />

                    {(error || (!existingUser && passwordStrength === 'weak')) && (
                        <Typography
                            sx={{
                                marginBottom: 2,
                                textAlign: 'left',
                                color: 'error.main'
                            }}
                        >
                            {error || 'Wachtwoord moet minimaal 8 tekens lang zijn.'}
                        </Typography>
                    )}

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} /> : (existingUser ? 'Word Host' : 'Registreer als Host')}
                    </Button>

                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        fullWidth
                        onClick={() => navigate('/')}
                        sx={{ mt: 1 }}
                    >
                        Terug
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default RegisterHost; 