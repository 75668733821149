import React from 'react';
import { Box } from '@mui/material';
import loader from '../../assets/rim-plug-v3.png';

interface FullScreenLoaderProps {
  message?: string;
}

const FullScreenLoader: React.FC<FullScreenLoaderProps> = () => {
  return (
    <Box
      data-testid="full-screen-loader"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.default',
        zIndex: 9999,
      }}
    >
      <Box
        component="img"
        src={loader}
        alt="Loading"
        sx={{
          width: 60,
          height: 60,
          animation: 'spin 1s linear infinite',
          '@keyframes spin': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
          },
        }}
      />
    </Box>
  );
};

export default FullScreenLoader;