import React, { useState, useCallback, useEffect } from 'react';
import { Button, debounce, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useStytchUser } from '@stytch/react';
import AuthModal from '../auth-modal/AuthModal';
import VerifyEmailModal from '../verify-email-modal/VerifyEmailModal';
import { useMeets } from '../../hooks/useMeets';

type AttendButtonProps = {
    meetId: string;
    isAttending: boolean;
    attendeesCount: number;
    onAttendChange: (isAttending: boolean, newAttendeesCount: number) => void;
    loading?: boolean;
    meetDate: Date;
    isUserVerified: boolean;
    email?: string;
}

const AttendButton: React.FC<AttendButtonProps> = ({ meetId, isAttending, isUserVerified, attendeesCount, onAttendChange, loading, meetDate, email }) => {
    const { attendMeet, unattendMeet } = useMeets();
    const [isAttended, setIsAttended] = useState(isAttending);
    const [animateAttend, setAnimateAttend] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);
    const { user: stytchUser } = useStytchUser();
    const isMeetStarted = new Date() >= new Date(meetDate);

    // Add useEffect to sync with parent's isAttending prop
    useEffect(() => {
        setIsAttended(isAttending);
    }, [isAttending, stytchUser]);

    useEffect(() => {
        setIsProcessing(loading || false);
    }, [loading]);

    // Debounced version of the attendance update
    const debouncedAttendanceUpdate = useCallback(
        debounce(async (shouldAttend: boolean) => {
            try {
                if (shouldAttend) {
                    await attendMeet(meetId, meetDate);
                    onAttendChange(true, attendeesCount + 1);
                } else {
                    await unattendMeet(meetId, meetDate);
                    onAttendChange(false, attendeesCount - 1);
                }
            } catch (error) {
                setIsAttended(!shouldAttend);
                console.error('Failed to update attendance:', error);
            } finally {
                setIsProcessing(false);
            }
        }, 500),
        [meetId, attendeesCount, meetDate]
    );

    const handleAttendClick = async () => {
        if (isMeetStarted) return;

        if (stytchUser) {
            if (isProcessing) return;
            if (!isUserVerified) {
                setShowVerifyEmailModal(true);
                return;
            }

            setIsProcessing(true);
            setAnimateAttend(false);
            setIsAttended(!isAttended);
            setAnimateAttend(true);

            debouncedAttendanceUpdate(!isAttended);
        } else {
            setShowAuthModal(true);
        }
    };

    return (
        <>
            <Button
                data-testid="attend-button"
                endIcon={isMeetStarted ? (
                    <Typography sx={{ fontSize: '12pt' }}>🏁</Typography>
                ) : !isAttended || !stytchUser ? (
                    <CheckBoxOutlineBlankIcon sx={{ fontSize: '12pt', height: '14pt', width: '14pt' }} />
                ) : (
                    <Typography
                        sx={{
                            fontSize: '12pt',
                            animation: animateAttend ? 'bounceAttend 0.3s' : 'none',
                            '@keyframes bounceAttend': {
                                '0%': { transform: 'perspective(400px) scale(0)' },
                                '50%': { transform: 'perspective(400px) scale(1.5)' },
                                '100%': { transform: 'perspective(400px) scale(1)' },
                            }
                        }}
                    >
                        ✅
                    </Typography>
                )}
                aria-label="attend meet"
                onClick={handleAttendClick}
                variant={isAttended || isMeetStarted ? 'contained' : 'outlined'}
                color={isAttended || isMeetStarted ? 'primary' : 'secondary'}
                disabled={isProcessing || isMeetStarted}
                sx={{
                    minWidth: '140px',
                    alignItems: 'center',
                    height: '38px',
                    '&.Mui-disabled': isMeetStarted ? {
                        color: 'secondary.main'
                    } : {}
                }}
            >
                <Typography fontWeight="bold">{isMeetStarted ? 'Meet gestart' : 'Aanwezig'}</Typography>
            </Button>
            <AuthModal
                open={showAuthModal}
                onClose={() => setShowAuthModal(false)}
                message={'Log in om deel te nemen'}
            />
            {
                email && (
                    <VerifyEmailModal
                        open={showVerifyEmailModal}
                        onClose={() => setShowVerifyEmailModal(false)}
                        email={email}
                    />
                )
            }
        </>
    );
};

export default AttendButton;