import React, { useState } from 'react';
import { Box, Typography, Button, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Login from '../../pages/Login';
import ContactButtons from '../contact-buttons/ContactButtons';

interface AuthModalProps {
    open: boolean;
    onClose: () => void;
    message?: string;
    customButton?: React.ReactNode;
    showContactButtons?: boolean;
}

const AuthModal: React.FC<AuthModalProps> = ({ open, onClose, message, customButton, showContactButtons = false }) => {
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isRegister, setIsRegister] = useState(false);

    const handleLoginClick = () => {
        setIsRegister(false);
        setShowLoginModal(true);
        onClose();
    };

    const handleRegisterClick = () => {
        setIsRegister(true);
        setShowLoginModal(true);
        onClose();
    };

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="auth-modal-title"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 320,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    pt: 1,
                    borderRadius: 2,
                    outline: 'none',
                }}>
                    <Box sx={{ display: 'flex', width: '100%', mb: 3, justifyContent: 'space-between', textAlign: 'center' }}>
                        <Typography id="auth-modal-title" variant="h6" component="h2" textAlign={'left'} gutterBottom>
                            {message || 'Log in of meld je aan'}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon sx={{ fontSize: '16pt' }} />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {customButton || (
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleLoginClick}
                                    data-testid="login-button"
                                    fullWidth
                                >
                                    Inloggen
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleRegisterClick}
                                    fullWidth
                                >
                                    Registreren
                                </Button>
                            </>
                        )}

                        {showContactButtons && <ContactButtons showDivider={false} />}
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={showLoginModal}
                sx={{
                    zIndex: (theme) => theme.zIndex.modal + 1
                }}
            >
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    bgcolor: 'background.default'
                }}>
                    <Login register={isRegister} onClose={() => setShowLoginModal(false)} />
                </Box>
            </Modal>
        </>
    );
};

export default AuthModal;