import React from 'react';
import { Box, Typography } from '@mui/material';

const AttendeesCounter = ({ attendeeCount, size = 'small' }: { attendeeCount: number, size?: 'small' | 'medium' }) => (
    <Box
        data-testid="attendees-counter"
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            backgroundColor: 'rgba(28, 28, 28, 0.8)',
            borderRadius: '100px',
            padding: '4px 8px',
            width: 'fit-content'
        }}>
        <Typography
            component="span"
            variant="body2"
            sx={{
                opacity: attendeeCount < 1 ? 0.3 : 1,
                fontSize: size === 'small' ? '12pt' : '14pt'
            }}
        >
            🚗
        </Typography>
        <Typography
            variant="body2"
            color="text.secondary"
            sx={{
                fontSize: size === 'small' ? '12pt' : '14pt'
            }}
        >
            {attendeeCount >= 1
                ? attendeeCount
                : '-'
            }
        </Typography>
    </Box>
);

export default AttendeesCounter;
