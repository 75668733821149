import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InstagramIcon from '@mui/icons-material/Instagram';

type ContactButtonsProps = {
    showDivider?: boolean;
    showHeader?: boolean;
};

const ContactButtons: React.FC<ContactButtonsProps> = ({ 
    showDivider = true, 
    showHeader = true 
}) => {
    return (
        <>
            {showDivider && <Divider sx={{ my: 2 }} />}
            
            {showHeader && (
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 1 }}>
                    Contact
                </Typography>
            )}
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
                <Button
                    variant="outlined"
                    href="mailto:info@carmeeter.com"
                    startIcon={<MailOutlineIcon />}
                    sx={{
                        width: '100%',
                        color: 'text.primary',
                        borderRadius: 1,
                        padding: '8px 16px',
                        transition: 'all 0.2s ease-in-out',
                    }}
                >
                    E-mail
                </Button>
                
                <Button
                    variant="outlined"
                    href="https://instagram.com/carmeeterapp"
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<InstagramIcon />}
                    sx={{
                        width: '100%',
                        color: 'text.primary',
                        borderRadius: 1,
                        padding: '8px 16px',
                        transition: 'all 0.2s ease-in-out',
                    }}
                >
                    Instagram
                </Button>
            </Box>
        </>
    );
};

export default ContactButtons; 