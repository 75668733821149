import React from 'react';
import { Box, Typography } from '@mui/material';

const DistanceCounter = ({ distance }: { distance: number }) => (
    <Box
        data-testid="distance-counter"
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            backgroundColor: 'rgba(28, 28, 28, 0.8)',
            borderRadius: '100px',
            padding: '4px 8px',
            width: 'fit-content'
        }}>
        <Typography
            component="span"
            variant="body2"
            sx={{
                fontSize: '12pt'
            }}
        >
            📍
        </Typography>
        <Typography
            variant="body2"
            color="text.secondary"
            sx={{
                fontSize: '12pt'
            }}
        >
            {distance} km
        </Typography>
    </Box>
);

export default DistanceCounter; 