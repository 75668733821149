import { useEffect, useState } from 'react';
import { HostTier, User } from '../types/User';
import { canAccessFeature } from '../utils/hostTierUtils';
import UserService from '../services/userService';

export const useHost = (requiredTier: HostTier) => {
    const [canAccess, setCanAccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hostName, setHostName] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const userService = UserService();
    useEffect(() => {
        const checkHostTier = async () => {
            const user = await userService.getMe();
            if (!user) {
                setCanAccess(false);
                setIsLoading(false);
                setHostName('');
                return;
            }
            const hasAccess = user.role === 'host' && canAccessFeature(user.hostTier as HostTier, requiredTier);
            setCanAccess(hasAccess || user.isAdmin);
            setIsLoading(false);
            setHostName(user.hostName || '');
            setIsAdmin(user.isAdmin);
        };
        checkHostTier();
    }, [requiredTier]);

    return { canAccess, isLoading, hostName, isAdmin };
}; 